@use "../../assets/css/index.scss" as *;

.otg-modal {
  display: none;
  position: fixed;
  z-index: 1;
  inset: 0;
  overflow: auto;
  background-color: rgba(0,0,0,0.15);

  &.show {
    @include flex-center;
  }

  > .otg-modal-container {
    background-color: rgba(#fefefe, 0.9);
    padding: 24px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 8px;
    min-width: 30%;
    max-width: 80%;
    max-height: 60%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    backdrop-filter: blur(80px);

    .otg-close {
      color: #aaa;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  
  .otg-close:hover,
  .otg-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .otg-modal-header {
    font-size: 35px;
    font-weight: 600;
    color: $header;
    line-height: 35px;
  }

  .otg-modal-content {
    font-size: 20px;
    max-height: 500px;
    overflow-y: auto;
  }

  .otg-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}



