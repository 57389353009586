@use "../../assets/css/index.scss" as *;

.otg-button {
  all: unset;
  border-radius: 8px;
  padding: 10px 18px;
  background: #3C60CC;
  color: white;
  font-weight: bold;
  font-size: 20px;
  box-shadow: $smallBtnBoxShadow;
  transition: 0.2s ease box-shadow;
  cursor: pointer;
  
  &:not(.readonly) {
    &:active {
      box-shadow: none !important;
    }
  }

  &.otg-secondary-btn {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 13px;
    background-color: $secondaryButton;
  }

  &.otg-confirm-btn, &.otg-cancel-btn {
    @include flex-col-center;
    width: 600px;
    height: 104px;
    border-radius: 20px;
    font-size: 36px;
    font-weight: 900;
    box-shadow: $bigBtnBoxShadow;
  }

  &.disabled {
    background-color: $disabledButton;
  }

  &.readonly {
    pointer-events: none;
  }
}

.otg-icon-button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.2s ease box-shadow;
  padding: 5px;

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover:not(:active) {
    box-shadow: $smallBtnBoxShadow;
  }

  &:active {
    outline: 1px solid rgba($color: #000000, $alpha: 0.12);
  }
}
