@use "../../assets/css/index.scss" as *;

.otg-main-body {
  @include flex-center;
  flex-grow: 1;
  position: relative;
  padding-inline: 10px;

  .page-content {
    @include flex-center;
    flex-grow: 1;

    > .page-header {
      @include flex-col-center;
      @include common-msg;
      font-size: 40px;
      font-weight: 700;
      color: $header;
      position: absolute;
      top: 55px;
      left: 50%;
      translate: -50% 0;
    }

    > .message {
      @include common-msg;
      font-weight: 600;
      font-size: 60px;
      color: $messageNormal;

      &.center-screen {
        padding-inline: 20px;
      }
    }

    > .info {
      font-weight: 700;
      font-size: 52px;
    }
  }
}
