@use '../../assets/css/index.scss' as *;

.otg-main-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px #{$separator};
  height: 80px;
  padding-left: 40px;

  .otg-logo {
    display: flex;
    align-items: center;
  }

  .otg-info {
    display: flex;
    align-items: center;
    height: 100%;
    // gap: 10px;

    > * {
      transition: transform 0.3s ease, opacity 0.2s ease;
    }

    &.has-info {
      .user-info {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &:not(.has-info) {
      .user-info {
        transform: translateX(100%);
        opacity: 0;
      }
    }

    > .tooltip-wrapper {
      @include flex-center;
      align-self: stretch;
      padding: 10px;
      border-left: 1px solid $separator;
      background-color: white;
      // z-index: 1;
      aspect-ratio: 1;
      height: 100%;
    }

    > .change-language {
      @include flex-center;
      align-self: stretch;
      padding: 10px;
      border-left: 1px solid $separator;
      background-color: white;
      aspect-ratio: 1;
      margin-left: 10;
    }

    .otg-icon-button {
      padding: 7px 5px 5px 7px;
      color: $messageNormal;

      .otg-icon {
        color: $messageNormal;
      }
    }

    .otg-icon-button > i {
      font-size: 40px;
    }

    > .tooltip-wrapper .info-tooltip {
      .info-wrapper {
        display: grid;
        align-items: center;
        grid-template-columns: auto minmax(0, 1fr);
        width: 300px;
        gap: 5px 10px;

        .strong {
          font-weight: bold;
        }
      }
    }

    > .user-info {
      color: $black;
      font-size: 22px;
      font-weight: 600;
      margin-right: 10px;
    }
  }
}
