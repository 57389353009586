@use "../../assets/index.scss" as *;

.header-dropdown {
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 10px 20px;
    box-sizing: border-box;
    position: sticky;
    top: 18px;
    background: white;
    z-index: 99;
    color: $grey190;
    font-size: 20px;
    font-weight: 600;
    > * {
        width: 100%;
    }
    .close-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        min-height: 30px;
        height: auto;
        color: $grey130;
    }
}

.body-dropdown {
    padding: 10px 20px;
    box-sizing: border-box;
}