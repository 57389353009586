@use '../../assets/css/index.scss' as *;

.otg-popover {
  position: relative;
  // left: 11px;

  > .otg-popover-display {
  }

  > .otg-popover-content {
    position: absolute;
    transition: 0.2s ease opacity;

    &:not(.opened) {
      opacity: 0;
    }

    &.opened {
      opacity: 1;
    }
    
    z-index: 1;
    transform: translateY(7px);
    background-color: $tileList;
    border-radius: 6px;
    padding: 15px;
    min-width: fit-content;
    right: -2px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    // box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 8px 16px rgba(0,0,0,0.14);

    &::after {
      content: '';
      position: absolute;
      border-width: 8px;
      border-color: transparent;
      border-bottom-color: $tileList;
      border-style: solid;
      z-index: 99;
      top: -16px;
      right: 20px;
      width: 10px;
      height: 10px;
    }
  }
}
