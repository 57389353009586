.ms-Dialog-content {
    max-width: 500px;
    max-height: 300px;
    overflow-y: auto;
    word-break: break-word;
}


.ms-Dialog-inner {
    padding-bottom: 10px; 
}

.ms-Dialog-action button {
    --tw-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);
    --tw-shadow-colored: 0 1.6px 3.6px 0 var(--tw-shadow-color), 0 0.3px 0.9px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}