
$header: #1746A2;
$messageNormal: #0D2C54;
$messageHighlighted: #3C60CC;
$tileList: #EDEBE9;
$tileText: #605E5C;
$tileTextAttention: #F25C54;
$primaryButton: #3C60CC;
$secondaryButton: #5F9DF7;
$disabledButton: #D2D2D2;
$listItem: #F4F4F9B2;
$listItemHover: #F4F4F900;
$paging: #9F9F9F;
$separator: #EAEDF6;
$white: #FFF;
$black: #14142B;

$grey30: #edebe9;
$grey90: #a19f9d;

$disableTextColor: #CCCCCC;
$disableBgColor: #EFF0F6;
$enableTextColor: #A7C7F7;
$smallBtnBoxShadow:
  rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
  rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

$bigBtnBoxShadow:
  rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
  rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
