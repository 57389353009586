@use "../../assets/css/index.scss" as *;

.otg-traditional-spinner {
  @include flex-center;

  .spinner-inner {
    @include flex-center;
    
    & > i {
      animation: 0.8s otg-spinner infinite;
      animation-timing-function: cubic-bezier(.56,.21,.49,.86);
    }
  } 
}

@keyframes otg-spinner {
  from {
    transform: rotateZ(-45deg);
  }

  to {
    transform: rotateZ(315deg);
  }
}

.otg-bar-spinner {
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  padding: 3px;
  overflow: visible;
  margin: auto;
  color: #0071c2;

  > circle {
    fill: none;
    stroke: currentColor;
    cx: 50%;
    cy: 50%;
    r: 50%;
    stroke-width: 6px;
    stroke-linecap: round;
    transform-origin: center;
  }
}

.otg-dots-spinner {
  width: 50px;
  height: 50px;
  margin: auto;

  .circle {
    position: absolute;
    width: 38px;
    height: 38px;
    opacity: 0;
    transform: rotate(225deg);
    animation-iteration-count: infinite;
    animation-name: orbit;
    // animation-duration: 5.5s;
    animation-duration: 4.5s;
    
    &::after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 5px;
      background-color: $header;
    }
    
    &:nth-child(2) {
      animation-delay: 240ms;
    }

    &:nth-child(3) {
      animation-delay: 480ms;
    }

    &:nth-child(4) {
      animation-delay: 720ms;
    }

    &:nth-child(5) {
      animation-delay: 960ms;
    }
  }
}

// .otg-spinner .bg {
//   position: absolute;
//   width: 70px;
//   height: 70px;
//   margin-left: -16px;
//   margin-top: -16px;
//   border-radius: 13px;
//   background-color: rgba(0, 153, 255, 0.69);
// }

@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }
  76% {
    transform: rotate(945deg);
    opacity: 0;
  }
  100% {
    transform: rotate(945deg);
    opacity: 0;
  }
}
