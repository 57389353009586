@use "./variables" as *;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: tabular-nums;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  user-select: none;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.text-center {
  text-align: center;
}

iframe {
  pointer-events: none;
}

.center-screen {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.p-inline-30 {
  padding-inline: 30px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.due-status-1 {
  color: #00820f;
}

.due-status-2 {
  color: #d2ae00;
}

.due-status-3 {
  color: #c40000;
}

.due-status-4 {
  visibility: hidden;
}

::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background-color: $grey30;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-clip: content-box;
  background-color: $grey90;
}
:root {
  --toastify-color-info: #3C60CC;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-toast-width: 400px;
  --toastify-toast-min-height: 73px;
  --toastify-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}