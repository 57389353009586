@use "../../assets/index.scss" as *;

.pwsb-panel {

  input[type="text"],
  textarea {
    min-height: 32px;
    border: none;
    font-size: 14px;

    &:focus {
      border: none;
    }
  }

  input::placeholder {
    color: $grey90;
  }

  .ms-Panel-commands {
    background-color: $white;
    height: 0px !important;
    padding: 0 !important;
  }

  .pwsb-panel-header,
  .pwsb-panel-body {
    padding: {
      left: 24px;
      right: 14px;
    }

    &.is-loading {
      gap: 0.5rem;

      > .ms-Shimmer-container > .ms-Shimmer-shimmerWrapper {
        height: 28px;
      }
    }
  }

  .pwsb-panel-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    box-sizing: border-box;
    padding-top: 18px;
    position: sticky;
    top: 0;
    right: 24px;
    background: $white;
    z-index: 99;

    .title {
      color: $black;
      font-size: 20px;
      font-weight: 600;
    }

    .content {
      font-size: 12px;
    }

    > * {
      width: 100%;
    }

    .close-button {
      position: absolute;
      right: 0;
      top: 18px;
      right: 14px;
      color: $grey130;
      width: 32px;
      height: 32px;
      max-height: 32px;
    }

    .refresh-button {
      position: absolute;
      top: 18px;
      right: 46px;
      color: $grey130;
      width: 32px;
      height: 32px;
      max-height: 32px;
    }
  }

  .pwsb-panel-body {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-grow: 1;
  }

  .pwsb-panel-footer {
    position: sticky;
    bottom: 0px;
    background-color: $white;
    z-index: 99;

    .pwsb-panel-footer-inner {
      padding: 16px 24px;

      padding: {
        top: 16px;
        bottom: 16px;
        left: 24px;
        right: 14px;
      }
    }
  }
}
