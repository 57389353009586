
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin flex-col-center {
  @include flex-col;
  align-items: center;
  justify-content: center;
}


@mixin common-msg {
  white-space: pre-line;
  text-align: center;
}
