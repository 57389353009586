@use '../../assets/css/index.scss' as *;

.otg-setup {
  padding-top: 20px;
  width: 100%;
  height: 100%;

  .stepper-tabs {
    justify-content: center;
    display: flex;

    ul {
      display: flex;
      list-style-type: none;

      li {
        flex: 1;
        margin: 0 5px;
        border-radius: 20px;
        text-align: center;
        padding: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px 50px;
        font-weight: 700;
        color: $disableTextColor;
        background-color: $disableBgColor;
        cursor: pointer;
      }

      .active {
        color: $white !important;
        background-color: $header !important;
      }

      .enable {
        color: $white !important;
        background-color: $enableTextColor !important;
      }

      .disabled {
        background-color: $disableBgColor !important;
        pointer-events: none;
      }
    }
  }

  .tab-content {
    display: flex;
    padding-top: 30px;
    height: calc(100% - 31px);

    .tab-info {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-bottom: 40px;

      h3 {
        color: $header;
        font-size: 40px;
      }

      span {
        color: $messageNormal;
        font-size: 46px;
        font-weight: 600;
        padding: 60px 0;
        white-space: pre-line;
        text-align: center;
      }

      .confirm-btn {
        .confirm-text {
          font-size: 46px;
          font-weight: 900;
          text-transform: uppercase;
        }
      }

    }

    .tab-list-items:not(.summarize) {
      flex: 1;

      .list {
        height: unset;

        .setup-info .row {
          font-size: 28px;
          color: $tileText;
          padding-inline: 10px;
          font-weight: 400;
        }
      }
    }

    .tab-list-items.summarize {
      flex: 1;
      display: flex;
      align-items: center;

      >.summarize {
        width: 46vw;
        background-color: $listItem;
        border-radius: 20px;
        padding: 27px;

        >.info {
          font-size: 44px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .row {
            display: flex;
            align-items: flex-start !important;
            gap: 15px;
            color: $tileText;

            span {
              overflow-wrap: anywhere;
            }
          }

          h3 {
            font-size: inherit;
            font-weight: 700;
          }
        }
      }
    }
  }

  .tab-list-items {}
}