@use "../../assets/variable.scss" as *;

.ms-Modal-scrollableContent {
    height: 100%;
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    gap: 20px;
    box-sizing: border-box;
    .modal-title {
        display: flex;
        flex-direction: column;
        gap: 12px;
        > span:first-child {
            max-height: 70px;
            color: $grey190;
            display: flex;
            align-items: center;
            font-weight: 600;
            box-sizing: border-box;
            font-size: 20px;
        }
        .text {
            flex-grow: 1;
        }        
    }

    .modal-body {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 15px;
        flex: auto;
        max-height: calc(100vh - 160px);
    }

    & .modal-footer {
        max-height: 60px;
        display: flex;
        gap: 15px;
    }
}