@use "../../assets/index.scss" as *;

.card-container {
    background-color: $white;
    color: $black;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 17px;
    box-sizing: border-box;
    gap: 10px;
    overflow: hidden;
    min-height: 74px;
    justify-content: center;

    @include screen-sm {
        padding: 17px 10px;
    }

    .card-title-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        >* {
            flex: 1;
        }

        .right-controls {
            display: flex;
            justify-content: flex-end;
        }

        .card-title {
            font-weight: 600;
            font-size: 16px;
        }
    }

    .card-content {
        @include row-flex;
        align-items: center;
        gap: 30px;
        position: relative;

        & > *:not(i) {
            flex: 1;
        }

        .details {
            @include column-flex;

            .name {
                font-weight: 400;
                word-break: break-word;
            }
        }

        .right-controls {
            @include row-flex;
            justify-content: flex-end;
            word-break: break-word;
        }

        @include screen-sm {
            gap: 15px;
        }
    }
}