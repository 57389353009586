
.page-content.info-screen {
  .page-header {
    font-size: 40px;
  }

  .message {
    & > .info-screen-message {
      text-align: center;
    }

    & > .info-screen-icon {
      font-size: 100px;
    }
  }
}
