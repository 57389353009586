@use '../../assets/css/index.scss' as *;

.otg-stepper {
  justify-content: center;
  display: flex;

  ul {
    display: flex;
    list-style-type: none;

    li {
      flex: 1;
      margin: 0 5px;
      border-radius: 20px;
      text-align: center;
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px 50px;
      font-weight: 700;
      color: $disableTextColor;
      background-color: $disableBgColor;
      transition: 0.3s ease all;

      &.clickable {
        cursor: pointer;
      }
  
      &.active {
        color: $white;
        background-color: $header;
      }
  
      &.stepped {
        color: $white;
        background-color: $enableTextColor;
      }
  
      &:not(.clickable) {
        pointer-events: none;
      }
    }
  }
}