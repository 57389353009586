@use '../../assets/css/index.scss' as *;

.otg-main-body .page-content.check-inout-page {
  margin-bottom: 50px;
  .message {
    .left {
      width: 38vw;
      gap: 56px;
      font-size: 50px;
      // position: relative;

      .header {
        @include common-msg;
        font-size: 40px;
        font-weight: 700;
        color: $header;
        text-transform: uppercase;
        position: absolute;
        top: 55px;
      }

      .scanning-message {
        line-height: 75px;
        font-size: 46px;
      }

      .otg-confirm-btn {
        .confirm-text {
          text-transform: uppercase;
        }
      }

      .otg-cancel-btn {
        &.cancel-btn {
          background-color: $secondaryButton;
          text-transform: uppercase;
        }
      }
    }
    .right {
      width: 55vw;
      .list {
        .list-item {
          font-size: 16px;
          color: $tileText;
          font-weight: 400;
        }

        .equipment-item {
          display: flex;
          align-items: center;
          width: 100%;
          align-self: stretch;
          justify-content: space-between;
          gap: 14px;
          padding-inline: 20px;

          .row.serial-number,
          .eq-not-found {
            color: $primaryButton;
            font-weight: 600;
            font-size: 22px;
          }

          > .info {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            width: 100%;
            > .info-left {
              text-align: left;
            }

            > .info-right {
              text-align: right;
              min-width: 30%;
            }

            > .info-left,
            > .info-right {
              > .row {
                font-size: 22px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                > .status-icon {
                  display: none;
                  &[class^='oi-'],
                  &[class*=' oi-'] {
                    display: inline-block;
                    margin-left: 10px;
                  }
                }
              }
            }

            > .info-left {
              display: flex;
              flex-direction: column;
              max-width: calc(70% - 10px);
            }
          }

          > .eq-not-found {
            flex-grow: 1;
            text-align: left;
            color: $primaryButton;
          }

          .separator {
            align-self: stretch;
            width: 1px;
            background-color: $tileList;
          }

          .actions {
            @include flex-center;
            position: relative;
            align-self: stretch;
            width: 176px;

            // > .need-service-text {
            //   position: absolute;
            //   top: 14px;
            //   font-size: 14px;
            //   font-weight: 700;
            //   color: $tileTextAttention;
            //   animation: fade-in 0.1s linear;
            // }

            > .need-service-btn {
              > .otg-button {
                text-transform: uppercase;
                font-weight: 900;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
