@use "../../assets/css/index.scss" as *;

.info-screen-columns.page-content {
  .message {
    display: flex;
    gap: 60px;

    .left {
      @include flex-col-center;
      font-size: 52px;
      width: 43vw;
      gap: 85px;
    }

    .right {
      flex-grow: 1;
      width: 46vw;
    }
  }
}