@use "../../assets/css/index.scss" as *;

.list {
  @include flex-col-center;
  gap: 10px;
  height: 100%;

  &.is-loading {
    height: 100% !important;
  }

  .list-container {
    @include flex-col;
    gap: 17px;
    width: 100%;
    padding-inline: 5px;
    padding-bottom: 5px;

    > .empty {
      font-size: 36px;
      font-weight: 400;
      color: $paging;
      margin: auto;

      >.no-records-found {
        color: $primaryButton;
      }
    }

    &.scrolling {
      overflow-y: auto;
      height: max(79.3vh, 750px);
    }
  }

  .list-paging {
    @include flex-center;
    align-self: flex-end;
    gap: 2px;
    padding: 3px;
    font-size: 28px;
    position: fixed;
    bottom: 20px;
    right: 25px;
    color: $paging;
    font-weight: 700;

    .paging-content {
      padding-inline: 10px;
    }
  }
}

.list-item {
  min-height: 18.5vh;
  max-height: 18.5vh;
}

.five-items {
  .list-item {
    height: 13.7vh;
    min-height: 13.7vh;
    max-height: 13.7vh;
  }
}

.list-item {
  @include flex-center;
  justify-content: space-between;
  border-radius: 20px;
  width: 100%;
  flex-grow: 1;
  gap: 14px;

  &.clickable {
    cursor: pointer;
    transition: 0.3s ease all;

    &:hover, &:active {
      background-color: $listItemHover;
    }
  }
  
  &:not(.no-default-padding) {
    padding: 10px 16px;
  }

  &:not(.list-item-empty) {
    background-color: $listItem;
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
}
