@use "../../assets/index.scss" as *;

.onix-table-container {
    max-height: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .width40 { width: 40px !important;  }
    .width60 { width: 60px !important; }
    .width80 { width: 80px !important; }

    .onix-table-wrapper {
        flex: 1;
        position: relative;
        .onix-table {
            & .clickable-cell {
                text-decoration: underline;
                cursor: pointer;
                color: $textLink;
                width: fit-content;
                max-width: 100%;
            }
        }
        .empty-table {
            @include row-flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }
    .total-items {
        @include row-flex;
        gap: 5px;
        box-sizing: border-box;
        font-weight: normal;
        padding: {
            top: 5px;
            bottom: 5px;
            left: 17px
        };
        
        >span {
            font-weight: bold;
        }
    }
}