$primaryColor: #069a8e;
$textColor: #fafafa;
$white: #ffffff;
$black: #000000;
$grey10: #FAF9F8;
$grey30: #EDEBE9;
$grey90: #a19f9d;
$grey130: #605E5C;
$grey110: #8A8886;
$grey190: #201F1E;
$grey160: #323130;
$red10: #d13438;

$textLink: rgb(12, 100, 192);