@use '../../assets/css/index.scss' as *;

.change-language-dialog {
  position: fixed;
  inset: 0;
  justify-content: center;
  align-items: center;
  // display: none;
  z-index: -1;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  display: flex;

  &.open {
    pointer-events: none;
    z-index: 1;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .change-language-container {
    pointer-events: auto;
    // z-index: 1;
    background: $white;
    width: 700px;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 48px;
    border-radius: 30px;
    padding: 28px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    backdrop-filter: blur(80px);
    max-height: 100vh;
    overflow: hidden;
    .header {
      font-size: 40px;
      font-weight: 600;
      color: $messageNormal;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .close-btn {
      // position: absolute;
      // top: 15px;
      // right: 15px;
      display: flex;
      align-items: center;
      font-size: 40px;
      color: $messageNormal;
      font-weight: 600;
      cursor: pointer;
    }

    .body {
      max-height: 100%;
      overflow-y: auto;

      .list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: stretch;
        .item {
          height: 88px;
          border-radius: 20px;
          background-color: $grey30;
          font-size: 40px;
          display: flex;
          align-items: center;
          padding-inline: 10px;
          cursor: pointer;
          gap: 10px;
  
          .checkmark {
            font-size: 30px;
            width: 64px;
            color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
          }
  
          &.selected {
            background-color: $header;
            color: $white;
  
            .checkmark {
              color: $white;
            }
          }
        }
      }
    }

    

    &.loading .list * {
      opacity: 0.65;
    }
  }

  .spinner {
    @include flex-center;
    font-size: 40px;
    color: $messageNormal;
    z-index: -1;
    background-color: $white;
    aspect-ratio: 1;
    border-radius: 20px;
    position: absolute;
    width: 100px;
    height: 100px;
    // display: none;
    display: flex;
    opacity: 0;
    transition: all 0.2s ease;

    &.show {
      z-index: 1;
      opacity: 1;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
}
